import React from 'react';

import { SignupLandingPage } from 'components/pages';
import Layout from 'components/global/layout';
import { SEO_START_YOUR_TRIAL, SEO_START_YOUR_TRIAL_TODAY } from 'consts/copy';

const FreeTrialSignupPage = () => (
  <Layout
    nav={{ isTransparent: true }}
    seo={{
      title: `Treat Fine Lines, Dark Spots, and More | Agency Custom Anti-Aging Skincare | ${SEO_START_YOUR_TRIAL}`,
      description: `Anti-aging skincare personalized by a dermatology provider. Formulas that adapt as your skin evolves. ${SEO_START_YOUR_TRIAL_TODAY}`,
      ogTitle: `Customized Anti-Aging Skincare | Agency | ${SEO_START_YOUR_TRIAL}`,
      ogDescription: `Unique, customized formulas prescribed by a licensed dermatology provider who knows your unique skin. ${SEO_START_YOUR_TRIAL_TODAY}`,
    }}
  >
    <SignupLandingPage />
  </Layout>
);

export default FreeTrialSignupPage;
